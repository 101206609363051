import { useEffect, useRef, useState } from "react"
import { ForecastRasterInfo } from "../utils/types"
import React from "react"
import moment, { Moment } from "moment"
export default function TimeBar({
  forecastRasterInfo,
  setForecastDate,
  forecastDate,
}: {
  forecastRasterInfo: ForecastRasterInfo
  setForecastDate: (date: Moment) => void
  forecastDate: Moment
}) {
  const getForecastRasterDatesWithInfo = () => {
    const forecastRasterDatesWithInfo = forecastRasterInfo.available_dates.map(
      (date) => {
        const momentDate = moment.utc(date)
        return {
          dateStringUtc: date,
          dayLocal: momentDate.local().format("ddd DD/MM"),
          hourLocal: momentDate.local().format("H"),
          isHidden: momentDate.isSame(forecastDate, "day") ? false : true,
          isActive: momentDate.isSame(forecastDate),
        }
      }
    )
    return forecastRasterDatesWithInfo
  }
  const [forecastRasterDatesWithInfo, setForecastRasterDatesWithInfo] =
    useState(getForecastRasterDatesWithInfo())
  useEffect(() => {
  }, [forecastRasterDatesWithInfo])

  useEffect(() => {
    setForecastRasterDatesWithInfo(getForecastRasterDatesWithInfo())
  }, [forecastRasterInfo, forecastDate])

  const containerRefHour = useRef<HTMLDivElement>(null)
  const containerRefDate = useRef<HTMLDivElement>(null)
  const handleScrollLeft = (containerRef: React.RefObject<HTMLDivElement>) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" })
    }
  }

  const handleScrollRight = (containerRef: React.RefObject<HTMLDivElement>) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" })
    }
  }

  const activeButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (activeButtonRef.current) {
      activeButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }
  }, [forecastRasterDatesWithInfo])
  const handleClickOnDate = (uniqueDate: string) => {
    const datesFiltered = forecastRasterDatesWithInfo.filter(
      (x) => x.dayLocal === uniqueDate
    )
    const oldActiveHour = forecastRasterDatesWithInfo.find((x) => x.isActive)?.hourLocal
    if (!oldActiveHour) return
    const newDate = datesFiltered.reduce((acc, curr) => {
      const delta = Math.abs(parseInt(curr.hourLocal) - parseInt(oldActiveHour))
      const bestDelta = Math.abs(parseInt(acc.hourLocal) - parseInt(oldActiveHour))
      return delta < bestDelta ? curr : acc
    })
    
    setForecastDate(moment.utc(newDate.dateStringUtc))
  }
  return (
    <div className="flex flex-col gap-1 w-full sm:w-2/3 pr-12 pl-1 pb-2 sm:pl-0 sm:pr-0 sm:pb-2">
      <div className="flex gap-1 pointer-events-auto items-center overflow-hidden  bg-white rounded-sm bg-opacity-50">
       
        
          <div
            className="flex overflow-x-auto scroll-smooth w-full"
            ref={containerRefHour}
          >
            {forecastRasterDatesWithInfo
              .filter((x) => !x.isHidden)
              .map((data, index) => (
                <div key={index} className="">
                  <button
                    className={`border-r-2 p-2 text-black hover:bg-white-600 ${
                      data.isActive ? "bg-blue-800 text-white" : ""
                    }`}
                    ref={data.isActive ? activeButtonRef : null}
                    onClick={() => {
                      setForecastDate(moment.utc(data.dateStringUtc))
                    }}
                  >
                    {data.hourLocal}h
                  </button>
                </div>
              ))}
          </div>
        
        
      </div>
      <div className=" gap-1 pointer-events-auto flex items-center overflow-hidden  bg-white rounded-sm bg-opacity-50">
          <div
            className="flex overflow-x-auto scroll-smooth w-full"
            ref={containerRefDate}
          >
            {forecastRasterDatesWithInfo
              .map((data) => data.dayLocal)
              .filter((x, index, self) => self.indexOf(x) === index)
              .map((uniqueDate, index) => (
                <div key={index} className="">
                  <button
                    className={`border-r-2 text-black p-2 ${
                      forecastRasterDatesWithInfo.find((x) => x.isActive)
                        ?.dayLocal === uniqueDate
                        ? "bg-blue-800 text-white"
                        : ""
                    }`}
                    onClick={() => handleClickOnDate(uniqueDate)}
                  >
                    {uniqueDate}
                  </button>
                </div>
              ))}
          </div>
        </div>
        
    </div>
  )
}
