import { Layer, Marker, MarkerDragEvent, Source } from "react-map-gl"
import { RoutePoints } from "../../interfaces/interfaces"
import IconPin from "../../icons/IconPin"
import * as turf from "@turf/turf"
import { useEffect, useState } from "react"
import { Routing } from "../../../utils/types"
export default function DisplayRoutePoints({
  routePoints,
  setRoutePoints,
  directRouteLine,
  routing,
}: {
  routePoints: RoutePoints
  setRoutePoints: (arg: RoutePoints) => void
  directRouteLine: turf.Feature<turf.LineString | turf.MultiLineString> | null
  routing: Routing | null
}) {
  const [distance, setDistanceFeature] = useState<string>("")

  useEffect(() => {
    if (routePoints.startPoint === null || routePoints.finishPoint === null) {
      setDistanceFeature("")
      return
    }
    const startPointTurf = turf.point([
      routePoints.startPoint.longitude,
      routePoints.startPoint.latitude,
    ])
    const finishPointTurf = turf.point([
      routePoints.finishPoint.longitude,
      routePoints.finishPoint.latitude,
    ])
    const distance = turf.distance(startPointTurf, finishPointTurf, {
      units: "nauticalmiles",
    })

    setDistanceFeature(distance.toFixed(2) + " NM")
  }, [routePoints])

  const onMarkerDrag = (event: MarkerDragEvent, name: string) => {
    setRoutePoints({
      ...routePoints,
      [name]: { latitude: event.lngLat.lat, longitude: event.lngLat.lng },
    })
  }
  return (
    <>
      {routePoints.startPoint && routePoints.finishPoint && directRouteLine && (
        <>
          <Source id="direct_route" type="geojson" data={directRouteLine}>
            <Layer
              id="direct_route"
              type="line"
              source="direct_route"
              paint={{
                "line-color": "white",
                "line-dasharray": [5, 5],
                "line-width": 1,
              }}
            />
          </Source>
          <Source id="midpoint" type="geojson" data={directRouteLine}>
            <Layer
              id="midpoint_label"
              type="symbol"
              source="midpoint"
              layout={{
                "text-field": distance,
                "text-size": 14,
                "symbol-placement": "line-center",
                "text-offset": [0, 1],
              }}
              paint={{
                "text-color": "white",
              }}
            />
          </Source>
        </>
      )}
      {routePoints.startPoint && (
        <Marker
          key={"startPoint"}
          latitude={routePoints.startPoint.latitude}
          longitude={routePoints.startPoint.longitude}
          draggable={false}
          offset={[0, -24]}
          // onDrag={(event) => onMarkerDrag(event, "startPoint")}
        >
          <IconPin kind={"start"} />
        </Marker>
      )}
      {routePoints.finishPoint && (
        <Marker
          key={"finishPoint"}
          latitude={routePoints.finishPoint.latitude}
          longitude={routePoints.finishPoint.longitude}
          draggable={false}
          offset={[0, -24]}
          // onDrag={(event) => onMarkerDrag(event, "finishPoint")}
        >
          <IconPin kind={"finish"} />
        </Marker>
      )}
    </>
  )
}
