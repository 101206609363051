import Modal from "./Modal"

export default function PrivatePrivacy({ onClose }: { onClose: () => void }) {
  return (
    <Modal onClose={onClose}>
      <div className="px-6">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

        <p className="mb-4">
          Welcome to <strong>Sail Routing</strong> ("Company", "we", "our",
          "us"). We are committed to protecting your personal information and
          your right to privacy. If you have any questions or concerns about
          this privacy policy or our practices with regards to your personal
          information, please contact us at{" "}
          <a href="mailto:contact@sailrouting.com" className="text-blue-800">
            contact@sailrouting.com
          </a>
          .
        </p>

        <p className="mb-4">
          When you visit our website{" "}
          <a href="app.sailrouting.com" className="text-blue-800">
            app.sailrouting.com
          </a>{" "}
          and use our services, you trust us with your personal information. We
          take your privacy very seriously. In this privacy policy, we describe
          our privacy policy. We seek to explain to you in the clearest way
          possible what information we collect, how we use it, and what rights
          you have in relation to it. We hope you take some time to read through
          it carefully, as it is important. If there are any terms in this
          privacy policy that you do not agree with, please discontinue use of
          our Sites and our services.
        </p>

        <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>

        <p className="mb-4">
          We collect personal information that you provide to us such as name,
          address, and contact information.
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Personal Information:</strong> We collect personal
            information that you voluntarily provide to us when registering at
            the Sites expressing an interest in obtaining information about us
            or our products and services, when participating in activities on
            the Sites, or otherwise contacting us.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>

        <p className="mb-4">
          We use personal information collected via our Sites for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>To facilitate account creation and logon process.</li>
          <li>To send administrative information to you.</li>
          <li>To manage user accounts.</li>
          <li>To send you marketing and promotional communications.</li>
          <li>To post testimonials.</li>
          <li>To protect our Sites.</li>
          <li>To enforce our terms, conditions, and policies.</li>
          <li>To respond to legal requests and prevent harm.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Sharing Your Information</h2>

        <p className="mb-4">
          We only share and disclose your information in the following
          situations:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Compliance with Laws:</strong> We may disclose your
            information where we are legally required to do so in order to
            comply with applicable law, governmental requests, a judicial
            proceeding, court order, or legal process.
          </li>
          <li>
            <strong>Vital Interests and Legal Rights:</strong> We may disclose
            your information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our
            policies, suspected fraud, situations involving potential threats to
            the safety of any person and illegal activities, or as evidence in
            litigation in which we are involved.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Your Privacy Rights</h2>

        <p className="mb-4">
          In some regions (like the European Economic Area), you have rights
          that allow you greater access to and control over your personal
          information. These rights may include:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            Requesting access to and obtaining a copy of your personal
            information.
          </li>
          <li>
            Requesting rectification or erasure of your personal information.
          </li>
          <li>Restricting the processing of your personal information.</li>
          <li>Data portability.</li>
          <li>
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Data Retention</h2>

        <p className="mb-4">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).
        </p>

        <h2 className="text-2xl font-bold mb-4">
          Security of Your Information
        </h2>

        <p className="mb-4">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>

        <h2 className="text-2xl font-bold mb-4">
          Changes to This Privacy Policy
        </h2>

        <p className="mb-4">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons. We will notify you of any
          changes by posting the new privacy policy on our website. You are
          advised to review this privacy policy periodically for any changes.
        </p>

        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

        <p className="mb-4">
          If you have questions or comments about this policy, you may contact
          us at:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            Email:{" "}
            <a href="mailto:contact@sailrouting.com" className="text-blue-600">
              contact@sailrouting.com
            </a>
          </li>
        </ul>
      </div>
    </Modal>
  )
}
