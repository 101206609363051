import { useEffect, useState } from "react"
import * as turf from "@turf/turf"
import { MapRef } from "react-map-gl"
import RoutingOverLay from "./Overlay/RoutingOverLay"
import RoutingLayers from "./Layers/RoutingLayers"
import { useGlobalStore } from "../../store/globalStore"
import { CalculationInfo } from "../../utils/types"
import { Point } from "../interfaces/interfaces"
import { auth } from "../../firebase/firebase"

export type ErrorMessage = {
  type: "unknown" | "userRequestLimit" | "routingTaskError"
  message: string
  timeUntilNextRoutingAllowedInSeconds: number | null
}

type PostRoutingData = {
  start_point: Point
  end_point: Point
  spread_diffusion_angle?: number
  departure_date: string
  boat_motor_speed: number
}

type PostRoutingResponse = {
  routing_task_id: string
}

export default function ModuleRouting({
  mapRef,
}: {
  mapRef: React.RefObject<MapRef>
}) {
  const routing = useGlobalStore((state) => state.routing)
  const setRouting = useGlobalStore((state) => state.setRouting)
  const routePoints = useGlobalStore((state) => state.routePoints)
  const setShowRoutingConfirmation = useGlobalStore(
    (state) => state.setShowRoutingConfirmation
  )

  const [directRouteLine, setDirectRouteLine] = useState<turf.Feature<
    turf.LineString | turf.MultiLineString
  > | null>(null)

  const [routingIsLoading, setRoutingIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null)

  const [calculationInfo, setCalculationInfo] =
    useState<CalculationInfo | null>(null)

  useEffect(() => {
    if (routePoints.startPoint !== null && routePoints.finishPoint !== null) {
      const startPointTurf = turf.point([
        routePoints.startPoint.longitude,
        routePoints.startPoint.latitude,
      ])
      const finishPointTurf = turf.point([
        routePoints.finishPoint.longitude,
        routePoints.finishPoint.latitude,
      ])
      setDirectRouteLine(turf.greatCircle(startPointTurf, finishPointTurf))
    }
  }, [routePoints, setShowRoutingConfirmation])

  useEffect(() => {
    if (routing) {
      mapRef.current?.fitBounds([
        routing.bounds.south_west[0],
        routing.bounds.south_west[1],
        routing.bounds.north_east[0],
        routing.bounds.north_east[1],
      ])
    }
  }, [routing])

  const departureDate = useGlobalStore((state) => state.routingDepartureDate)
  const boatMotorSpeed = useGlobalStore((state) => state.boatMotorSpeed)
  async function calculate() {
    if (routePoints.startPoint === null || routePoints.finishPoint === null) {
      return
    }
    setRoutingIsLoading(true)

    const payload: PostRoutingData = {
      start_point: {
        latitude: routePoints.startPoint.latitude,
        longitude: routePoints.startPoint.longitude,
      },
      end_point: {
        latitude: routePoints.finishPoint.latitude,
        longitude: routePoints.finishPoint.longitude,
      },
      spread_diffusion_angle: 180,
      departure_date: departureDate.toISOString(), // UTC conversion (departureDate has local time zone)
      boat_motor_speed: boatMotorSpeed,
    }

    async function postRouting(payload: PostRoutingData) {
      let token = undefined
      try {
        token = await auth.currentUser?.getIdToken()
      } catch (error) {
        console.error("Error getting token", error)
        return Promise.reject(error)
      }
      const backendUrl = process.env.REACT_APP_BACKEND_URL
      try {
        const resp = await fetch(`${backendUrl}/routing/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
        if (resp.ok) {
          const response = await resp.json()
          const routing_task_id = response.routing_task_id
          getRoutingResult(routing_task_id)
        } else {
          if (resp.status === 429) {
            const errorMessage = await resp.json()
            const timeToWait = errorMessage.detail
            setErrorMessage({
              type: "userRequestLimit",
              message: "",
              timeUntilNextRoutingAllowedInSeconds: timeToWait,
            })
          } else {
            throw new Error(await resp.text())
          }
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }

    postRouting(payload).catch((error) => {
      setErrorMessage({
        type: "unknown",
        message: error.message,
        timeUntilNextRoutingAllowedInSeconds: null,
      })
    })
  }
  const [routingMaxIterationReached, setRoutingMaxIterationReached] =
    useState(false)
  async function getRoutingResult(routing_task_id: string) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const url = `${backendUrl}/routing/${routing_task_id}`
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.task_status === "SUCCESS") {
          setRouting(data.task_result)
          if (data.task_result.geo_json_isochrones.features.length >= 50) {
            setRoutingMaxIterationReached(true)
          }
          setRoutingIsLoading(false)
          setShowRoutingConfirmation(false)
        } else if (data.task_status === "PENDING") {
          setCalculationInfo({
            isochrones: 0,
            info: "Connection to the calculator...",
          })
          setTimeout(() => getRoutingResult(routing_task_id), 1000)
          return
        } else if (data.task_status === "STARTED") {
          setCalculationInfo({
            isochrones:
              data.task_result?.isochrones !== undefined
                ? data.task_result.isochrones
                : 0,
            info: "The route is being calculated...",
          })
          setTimeout(() => getRoutingResult(routing_task_id), 1000)
          return
        } else if (data.task_status === "FAILURE") {
          setErrorMessage(data.task_result)
        } else if (data.task_status === "REVOKED") {
          setErrorMessage({
            type: "routingTaskError",
            message:
              "We're experiencing a storm of routing requests, and all our server resources are currently tied up. Please check back later once the weather clears up. Thank you for your patience and understanding. We appreciate having you with us!",
            timeUntilNextRoutingAllowedInSeconds: null,
          })
        } else {
          setErrorMessage({
            type: "routingTaskError",
            message: "Error fetching the routing result.",
            timeUntilNextRoutingAllowedInSeconds: null,
          })
        }
      })
      .catch((error) =>
        console.error("Error fetching or parsing the JSON:", error)
      )
  }

  return (
    <>
      {routingMaxIterationReached && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-96">
            <div className="text-sm sm:text-base">
              <div className="mb-2 font-semibold">Routing Limit Reached</div>
              <div className="">
                We regret to inform you that the routing process has reached the
                maximum limit of 50 isochrone iterations. This may result in an
                incoherent routing outcome. Please consider adjusting your
                parameters to ensure a more accurate route calculation. Thank
                you for your understanding.
              </div>
            </div>
            <div className="text-center">
              <button
                onClick={() => setRoutingMaxIterationReached(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-md mt-4 font-bold"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      <RoutingOverLay
        routingIsLoading={routingIsLoading}
        routePoints={routePoints}
        calculate={calculate}
        errorMessage={errorMessage}
        setRoutingIsLoading={setRoutingIsLoading}
        setErrorMessage={setErrorMessage}
        calculationInfo={calculationInfo}
      />

      <RoutingLayers directRouteLine={directRouteLine} />
    </>
  )
}
