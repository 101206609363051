import moment from "moment"
import { Moment } from "moment"

export function findClosestDateIndex(
  referenceDate: Moment,
  dates: string[]
): number {
  let diff: number | undefined = undefined
  let index: number = 0
  dates.forEach((date) => {
    const currentDate = moment(date)
    const currentDiff = Math.abs(referenceDate.diff(currentDate, "minutes"))
    if (diff === undefined || currentDiff < diff) {
      diff = currentDiff
      index = dates.indexOf(date)
    }
  })
  return index
}

export function convertMinutesToDurationString(minutes: number) {
  var duration = moment.duration(minutes, "minutes")
  if (duration.asHours() < 24) {
    // Si la durée est inférieure à 24 heures, affiche en heures et minutes
    return duration.hours() + "h" + duration.minutes() + "min"
  } else {
    // Si la durée est de 24 heures ou plus, affiche en jours, heures, et minutes
    return (
      Math.floor(duration.asDays()) +
      "d" +
      duration.hours() +
      "h" +
      duration.minutes() +
      "min"
    )
  }
}
