import moment from "moment"
import { useGlobalStore } from "../store/globalStore"
import { findClosestDateIndex } from "./dates"

const setForecastRasterInfo = useGlobalStore.getState().setForecastRasterInfo
const setForecastDate = useGlobalStore.getState().setForecastDate
const setRoutingDepartureDate =
  useGlobalStore.getState().setRoutingDepartureDate

export const getAndSetForecastInfo = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const url = `${backendUrl}/forecast/dates/GFS_0p25`
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      setForecastRasterInfo({
        forecast_model: "GFS_0p25",
        available_dates: data,
      })
      const closestDateIndex = findClosestDateIndex(moment(), data)
      const forecastDate = moment.utc(data[closestDateIndex])
      setForecastDate(forecastDate)
      setRoutingDepartureDate(forecastDate)
    })
    .catch((error) =>
      console.error("Error fetching or parsing the JSON:", error)
    )
}
