import FullMap from "./components/FullMap"
import Header from "./components/Header"
import { AuthProvider } from "./contexts/authContext"

function App() {
  return (
    <AuthProvider>
      {/* h-svh -> mobile screen to adapt height screen when action buttons */}
      <div className="font-medium flex flex-col h-svh">
        <Header />
        <FullMap />
      </div>
    </AuthProvider>
  )
}

export default App
