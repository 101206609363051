// @ts-nocheck
import { BoxZoomHandler } from "mapbox-gl"
import Plot from "react-plotly.js"
export default function PolarGraph({
  polarDiagramData,
}: {
  polarDiagramData: [[number, number[], number[]]] | undefined
}) {
  const data = polarDiagramData?.map((x) => {
    return {
      type: "scatterpolar",
      r: x[1],
      theta: x[2],
      mode: "lines",
      name: x[0].toString() + " kts",
      line: {
        shape: "spline",
      },
    }
  })

  const layout = {
    width: 300,
    showlegend: true,
    legend: {
      x: -0.5,
      y: 1,
    },
    polar: {
      radialaxis: {
        visible: true,
        // range: [0, 10],
        autorange: true,
      },
      angularaxis: {
        rotation: 90,
        direction: "clockwise",
      },
      sector: [-90, 90],
    },
    title: "Boat Polar Diagram",
  }
  const config = {
    displayModeBar: false,
    scrollZoom: false,
    displaylogo: false,
    editable: false,
  }
  return (
    <div className="">
      {data && <Plot data={data} layout={layout} config={config} />}
    </div>
  )
}
