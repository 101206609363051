// tslint:disable
import { Routing } from "../../../utils/types"
import { Layer, Source } from "react-map-gl"

export default function DisplayIsochrones({ routing }: { routing: Routing }) {
  return (
    <>
      <Source
        id="isochrone_segments"
        type="geojson"
        data={routing.geo_json_isochrones}
      >
        <Layer
          id="isochrone_segments"
          type="line"
          source="isochrone_segments"
          paint={{
            "line-color": "#AD3333",
            "line-width": 1.5,
          }}
        />
      </Source>
    </>
  )
}
