import moment from "moment"
import { useGlobalStore } from "../store/globalStore"
import { ForecastRasterInfo } from "../utils/types"
import WindScale from "./WindScale"
import Toggle from "./uiComponents/Toggle"
import { convertMinutesToDurationString } from "../utils/dates"
import { useState } from "react"
import RoutingDetailsModal from "./RoutingDetailsModal"
import { ReactComponent as ExpandUpIcon } from "../components/icons/expandUp.svg"
import { ReactComponent as ExpandDownIcon } from "../components/icons/expandDown.svg"
import TimeBarMobile from "./TimeBarMobile"
export default function MainOverlay({
  forecastRasterInfo,
}: {
  forecastRasterInfo: ForecastRasterInfo | undefined
}) {
  const setForecastDate = useGlobalStore((state) => state.setForecastDate)
  const forecastDate = useGlobalStore((state) => state.forecastDate)
  const setShowGradient = useGlobalStore((state) => state.setShowGradient)
  const showGradient = useGlobalStore((state) => state.showGradient)
  const routing = useGlobalStore((state) => state.routing)
  const setRouting = useGlobalStore((state) => state.setRouting)
  const setRoutePoints = useGlobalStore((state) => state.setRoutePoints)
  const setShowRoutingConfirmation = useGlobalStore(
    (state) => state.setShowRoutingConfirmation
  )

  const [showRoutingDetails, setShowRoutingDetails] = useState(false)
  const [showRoutingInfo, setShowRoutingInfo] = useState(
    window.innerWidth > 640
  )

  return (
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none flex flex-col justify-between">
      {routing ? (
        <>
          <div className="flex flex-col items-end ">
            <div className="bg-white bg-opacity-75 p-3 mt-2 rounded-s pointer-events-auto w-50">
              <button
                className=""
                onClick={() => setShowRoutingInfo(!showRoutingInfo)}
              >
                <div className="flex">
                  <div className="flex flex-col grow">
                    <div className="font-semibold">ROUTING</div>
                    <div className="text-sm sm:text-lg bg-gray-700 text-white font-semibold rounded px-2">
                      {convertMinutesToDurationString(
                        routing.routing_stats.elapsed_time_in_minutes
                      )}{" "}
                      - {routing.routing_stats.distance_done.toFixed(2)} Nm
                    </div>
                  </div>

                  <div className="flex justify-around">
                    {showRoutingInfo ? (
                      <ExpandUpIcon className="h-6" />
                    ) : (
                      <ExpandDownIcon className="h-6" />
                    )}
                  </div>
                </div>
              </button>

              {showRoutingInfo && (
                <>
                  <div className="w-1/8 h-[1px] bg-gray-600 my-2"></div>
                  <div className="uppercase text-xs text-gray-700 font-semibold">
                    Departure Time
                  </div>
                  <div className="text">
                    {moment
                      .utc(routing.routing_stats.started_at)
                      .local()
                      .format("MMM Do HH:mm")}
                  </div>
                  <div className="uppercase text-xs text-gray-700 font-semibold">
                    Arrival Time
                  </div>
                  <div>
                    {moment
                      .utc(routing.routing_stats.ended_at)
                      .local()
                      .format("MMM Do HH:mm")}
                  </div>

                  <div className="flex flex-col gap-1">
                    <button
                      className="bg-gray-200 p-1 rounded text-black border-gray-300 border shadow-sm  font-semibold"
                      onClick={() => {
                        setShowRoutingDetails(true)
                      }}
                    >
                      Details
                    </button>

                    <button
                      onClick={() => {
                        // setShowRoutingInfo(false)
                        setShowRoutingConfirmation(true)
                      }}
                      className=" bg-gray-200 p-1 rounded text-black border-gray-300 border shadow-sm  font-semibold"
                    >
                      Modify
                    </button>
                    <button
                      className="bg-red-500 p-1 rounded text-white border-gray-300 border shadow-sm  font-semibold"
                      onClick={() => {
                        setRouting(null)
                        setRoutePoints({ startPoint: null, finishPoint: null })
                        setShowGradient(true)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {showRoutingDetails && routing && (
            <RoutingDetailsModal
              routing={routing}
              setShowModal={setShowRoutingDetails}
            />
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col items-end">
            <div>
              <button
                className="bg-red-700 text-white hover:bg-red-800 p-2 rounded-l-md font-semibold mt-2 pointer-events-auto"
                onClick={() => setShowRoutingConfirmation(true)}
              >
                Launch ROUTING
              </button>
              <div className="bg-white bg-opacity-75 p-1 md:p-3 mt-2 rounded-l-md pointer-events-auto flex flex-col items-end">
                <div className="flex font-semibold items-center gap-4">
                  <div>Forecast</div>
                  <Toggle
                    isChecked={showGradient}
                    setChecked={setShowGradient}
                  />
                </div>
                <div className="text-xs text-center">GFS - 0.25°</div>
              </div>
            </div>
          </div>
          {showGradient && (
            <>
              <div className="flex flex-row overflow-hidden">
                <div className="grow"></div>
                <WindScale />
              </div>
              <div className="mb-[0px]">
                {forecastRasterInfo && forecastDate && !routing && (
                  <div className="flex items-center justify-center">
                    <TimeBarMobile
                      forecastRasterInfo={forecastRasterInfo}
                      setForecastDate={setForecastDate}
                      forecastDate={forecastDate}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
