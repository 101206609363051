import moment from "moment"
import { Routing } from "../utils/types"
import { ReactComponent as CloseIcon } from "../components/icons/close.svg"
var formatcoords = require("formatcoords")
export default function RoutingDetailsModal({
  routing,
  setShowModal,
}: {
  routing: Routing
  setShowModal: (show: boolean) => void
}) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center pointer-events-auto">
      <div className="bg-white p-5 rounded-lg md:w-4/5 md:h-4/5 w-full h-full overflow-hidden">
        <div className="flex justify-between pb-2">
          <h2 className="font-bold text-lg">Routing details</h2>
          <button
            onClick={() => setShowModal(false)}
            className="flex flex-row items-center"
          >
            Close <CloseIcon className="h-8" />
          </button>
        </div>
        <div className="overflow-auto h-4/5">
        <table>
          <thead className="bg-gray-300">
            <tr>
              <th className="text-center border">Elapsed time</th>
              <th className="text-center border">SOG</th>
              <th className="text-center border">COG</th>
              <th className="text-center border">TWA</th>
              <th className="text-center border">Wind speed</th>
              <th className="text-center border">Wind dir.</th>
              <th className="text-center border">Position</th>
              <th className="text-center border">Date</th>
            </tr>
          </thead>
          <tbody>
            {routing.geo_json_path.features.map((feature, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? "bg-gray-100" : ""}`}
              >
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300">
                  +
                  {moment(feature.properties.started_at)
                    .diff(
                      moment(
                        routing.geo_json_path.features[0].properties.started_at
                      ),
                      "hours",
                      true
                    )
                    .toFixed(1) + " h"}
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {feature.properties.sog.toFixed(1)} kts
                  {feature.properties.is_motor ? " (motor)" : ""}
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {feature.properties.cog.toFixed()}°
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {feature.properties.twa.toFixed()}°
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {feature.properties.wind_speed.toFixed(1)} kts
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {feature.properties.wind_direction.toFixed()}°
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {formatcoords(
                    parseFloat(feature.properties.start_latitude.toFixed(5)),
                    parseFloat(feature.properties.start_longitude.toFixed(5))
                  ).format("f")}
                </td>
                <td className="text-right border border-t-0 border-b-0 p-1 border-gray-300 text-nowrap">
                  {moment
                    .utc(feature.properties.started_at)
                    .local()
                    .format("MMM Do HH:mm")}
                </td>
              </tr>
            ))}
          </tbody>
        </table></div>
      </div>
    </div>
  )
}
