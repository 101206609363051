import { useState } from "react"
import { doPasswordReset } from "../../firebase/auth"

export default function ResetPassword({
  setIsForgotPassword,
}: {
  setIsForgotPassword: (value: boolean) => void
}) {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isResetting, setIsResetting] = useState(false)
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    doPasswordReset(email)
      .then(() => {
        setIsResetting(true)
      })
      .catch((err) => {
        const errorCode = err.code
        if (errorCode === "auth/invalid-email") {
          setErrorMessage("Invalid email")
        } else if (errorCode === "auth/user-not-found") {
          setErrorMessage("User not found")
        } else {
          setErrorMessage(err.message)
        }
      })
  }
  return (
    <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl bg-white">
      <div className="text-center">
        <div className="mt-2">
          <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
            Reset password
          </h3>
        </div>
      </div>
      {isResetting ? (
        <div>
          If an account with the entered email address exists, we have sent an
          email with instructions on how to reset your password. Please check
          your inbox and follow the instructions to set a new password.
        </div>
      ) : (
        <form onSubmit={onSubmit} className="space-y-5">
          <div>
            <label className="text-sm text-gray-600 font-bold">Email</label>
            <input
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
            />
          </div>
          {errorMessage && (
            <span className="text-red-600 font-bold">{errorMessage}</span>
          )}
          <button
            type="submit"
            className="w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
          >
            Reset
          </button>
        </form>
      )}
      <div className="text-center">
        <button
          type="button"
          className="hover:underline text-indigo-600 font-bold transition duration-300"
          onClick={() => {
            setIsForgotPassword(false)
          }}
        >
          <div>Go back to login page</div>
        </button>
      </div>
    </div>
  )
}
