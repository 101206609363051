import { gradientColors } from "../utils/rasterColor"

export default function WindScale() {
  function rgbaToHex(rgba: string) {
    // Extraire les composantes Rouge, Vert, Bleu et Alpha de la chaîne rgba
    const parts = rgba.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/
    )

    if (!parts) {
      throw new Error("Format RGBA invalide.")
    }

    // Convertir les composantes Rouge, Vert et Bleu en hexadécimal et les concaténer
    let hex =
      "#" +
      parts
        .slice(1, 4)
        .map((color: string) => {
          return parseInt(color).toString(16).padStart(2, "0")
        })
        .join("")

    // Si l'Alpha est spécifié et différent de 1, convertir également Alpha en hexadécimal
    if (parts[4] && parseFloat(parts[4]) < 1) {
      const alpha = Math.round(parseFloat(parts[4]) * 255)
        .toString(16)
        .padStart(2, "0")
      hex += alpha
    }

    return hex
  }

  return (
    <div className="flex flex-row items-end">
      <div>
        <div className="text-black p-1 text-center bg-white rounded-t-lg">
          kts
        </div>
        {gradientColors.map((color, index) => {
          if (color.value > 55) return null
          const colorHexa = rgbaToHex(gradientColors[index + 1].color)
          return (
            <div
              key={index}
              style={{ backgroundColor: colorHexa }}
              className="text-white p-1 text-center text-xs sm:text-sm "
            >
              {color.value}
            </div>
          )
        })}
      </div>
    </div>
    // <div className="absolute bg-wind-gradient bottom-5 right-1 w-96 flex justify-evenly items-center rounded-xl border-gray-500 border h-6 shadow">
    //   {wind_speed_values.map((value) => (
    //     <div className="text-gray text-sm">{value}</div>
    //   ))}
    // </div>
  )
}
