import { User, onAuthStateChanged } from "firebase/auth"
import { createContext, useEffect, useState } from "react"
import { auth } from "../../firebase/firebase"

type AuthContextType = {
  currentUser: User | null
  userLoggedIn: boolean
  loading: boolean
  subscriptionType: string
  on_beta_waitlist: boolean
  refreshUserDataSubscription: () => void
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userLoggedIn: false,
  loading: true,
  subscriptionType: "basic",
  on_beta_waitlist: false,
  refreshUserDataSubscription: () => {},
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [subscriptionType, setSubscriptionType] = useState("basic")
  const [on_beta_waitlist, setOnBetaWaitlist] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser)
    return unsubscribe
  }, [])
  async function initializeUser(user: User | null) {
    if (user) {
      setCurrentUser({ ...user })
      setUserLoggedIn(true)
      fetchSubscriptionType(user)
    } else {
      setCurrentUser(null)
      setUserLoggedIn(false)
    }
    setLoading(false)
  }
  const fetchSubscriptionType = async (user: User) => {
    const token = await user.getIdTokenResult()
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    let url = `${backendUrl}/user/me`
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setSubscriptionType(data.subscription_type)
        setOnBetaWaitlist(data.on_beta_waitlist)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const refreshUserDataSubscription = async () => {
    const currentUser = auth.currentUser
    if (currentUser) {
      await fetchSubscriptionType(currentUser)
    }
  }
  const value = {
    currentUser,
    userLoggedIn,
    loading,
    subscriptionType,
    on_beta_waitlist,
    refreshUserDataSubscription,
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
