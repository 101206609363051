import { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../contexts/authContext"
import { useOutsideAlerter } from "../utils/clickOutside"
import { doSignOut } from "../firebase/auth"
import { User } from "firebase/auth"
import PrivatePrivacy from "./components/PrivatePrivacy"
import TermsOfService from "./components/TermsOfService"
import { useGlobalStore } from "../store/globalStore"

export default function Header() {
  const { userLoggedIn, currentUser } = useContext(AuthContext)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showPrivatePolicy, setShowPrivatePolicy] = useState(false)
  const [showTermsOfService, setShowTermsOfService] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const userMenuRef = useRef(null)
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  const setSectionSettings = useGlobalStore((state) => state.setSectionSettings)
  const menuRef = useRef(null)
  useOutsideAlerter(userMenuRef, setShowUserMenu)
  useOutsideAlerter(menuRef, setShowMenu)
  const getFirstLetter = (currentUser: User) => {
    if (currentUser.displayName) {
      return currentUser.displayName?.charAt(0).toUpperCase()
    }
    if (currentUser.email) {
      return currentUser.email?.charAt(0).toLocaleUpperCase()
    }
  }
  return (
    <>
      <div className="bg-slate-100 w-full border-b-2 border-slate-300 flex items-center relative">
        <div className="flex justify-between w-full items-center px-3 sm:py-2">
          <div className="flex gap-2 items-center">
            <img src="/logo_2_1.png" alt="logo" className="sm:h-8 h-5" />
            <div className="md:block hidden">
              <div className="text-lg font-semibold">Sail Routing</div>
              <div className="text-sm">Plan your route, sail smarter </div>
            </div>
          </div>

          <div className="flex gap-3">
            {/* <button
              className="w-8 h-8 relative md:hidden block"
              onClick={() => setShowMenu(!showMenu)}
              ref={menuRef}
            >
              <IconMenu />
              {showMenu && (
                <div className="absolute top-9 right-0 z-50 bg-slate-100 border rounded-md p-4 text-left"></div>
              )}
            </button> */}

            {userLoggedIn && currentUser && (
              <button
                ref={userMenuRef}
                className="hover:bg-gray-300 p-1 rounded"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                {currentUser.photoURL ? (
                  <img
                    src={currentUser.photoURL}
                    alt="user"
                    className="h-7 w-7 rounded-full"
                  />
                ) : (
                  <div className="h-7 w-7 rounded-full bg-indigo-500 flex items-center justify-center text-white text-sm">
                    {getFirstLetter(currentUser)}
                  </div>
                )}
                {showUserMenu && (
                  <div className="absolute bg-gray-100 shadow-sm rounded px-5 py-3 right-[5px] top-12 z-50 border border-gray-400 text-sm flex flex-col">
                    <button
                      className="hover:bg-gray-300 p-2 rounded text-left"
                      onClick={() => {
                        setSectionSettings("my boat")
                        setShowSettings(true)
                      }}
                    >
                      My boat
                    </button>
                    <button
                      className="hover:bg-gray-300 p-2 rounded text-left"
                      onClick={() => {
                        setSectionSettings("plans")
                        setShowSettings(true)
                      }}
                    >
                      Plans
                    </button>

                    <button
                      className="hover:bg-gray-300 p-2 rounded text-left"
                      onClick={() => {
                        setShowUserMenu(false)
                        doSignOut()
                      }}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {showPrivatePolicy && (
        <PrivatePrivacy onClose={() => setShowPrivatePolicy(false)} />
      )}
      {showTermsOfService && (
        <TermsOfService onClose={() => setShowTermsOfService(false)} />
      )}
    </>
  )
}
