import { Moment } from "moment"
import { useEffect, useState } from "react"
import { Layer, Source } from "react-map-gl"
import { getRasterColor, gradientColors } from "../../../utils/rasterColor"
import { getCanvasFromData } from "../../../utils/canvas"
import { useGlobalStore } from "../../../store/globalStore"
export default function DisplayColorWindForecast({
  forecastDate,
}: {
  forecastDate: Moment
}) {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const forecastData = useGlobalStore((state) => state.forecastData)

  useEffect(() => {
    if (!forecastData) {
      setCanvas(null)
      return
    }
    const canvas = getCanvasFromData(
      forecastData.u,
      forecastData.v,
      forecastData.longitudes,
      forecastData.latitudes
    )
    setCanvas(canvas)
  }, [forecastData])
  return (
    <>
      {canvas && forecastData && (
        <>
          <Source
            key={forecastDate.format()}
            id="wind-image"
            type="canvas"
            canvas={canvas}
            coordinates={[
              [forecastData.bounds[0], forecastData.bounds[3]],
              [forecastData.bounds[2], forecastData.bounds[3]],
              [forecastData.bounds[2], forecastData.bounds[1]],
              [forecastData.bounds[0], forecastData.bounds[1]],
            ]}
          >
            <Layer
              // beforeId="bathymetry-layer"
              id="forecast-color-layer"
              beforeId="windTrails"
              type="raster"
              source="wind-image"
              // minzoom={1}
              // maxzoom={8} // display the tile up to zoom level X
              paint={{
                "raster-opacity": 1,
                "raster-fade-duration": 0,
                "raster-resampling": "linear",
                // @ts-ignore
                "raster-color-mix": [255, 0, 0, 0],
                "raster-color-range": [0, 255],
                "raster-color": getRasterColor(gradientColors),
              }}
            ></Layer>
          </Source>
          <Source
            id="bathymetry"
            type="vector"
            url="mapbox://mapbox.country-boundaries-v1"
          >
            <Layer
              id="countries-layer-fill"
              type="fill"
              layout={{ visibility: "visible" }}
              source="bathymetry"
              source-layer="country_boundaries"
              paint={{
                "fill-color": "#000000",
                "fill-opacity": 0.1,
              }}
            ></Layer>
            <Layer
              id="bathymetry-layer"
              type="line"
              source="bathymetry"
              source-layer="country_boundaries"
              paint={{
                "line-color": "#282828",
                "line-width": 2,
                "line-blur": 2,
              }}
            ></Layer>
          </Source>
          <Source
            id="street"
            type="vector"
            url="mapbox://mapbox.mapbox-streets-v8"
          >
            <Layer
              id="road-layer"
              type="line"
              source="street"
              source-layer="road"
              filter={[
                "in",
                ["get", "class"],
                [
                  "literal",
                  [
                    "motorway",
                    "motorway_link",
                    "primary",
                    "secondary",
                    "tertiary",
                  ],
                ],
              ]}
              paint={{
                "line-color": "#282828",
                "line-width": 1,
                "line-opacity": 0.3,
              }}
            ></Layer>
            <Layer
              id="name-layer"
              type="symbol"
              source="street"
              source-layer="place_label"
              filter={[
                "all",
                [
                  "in",
                  ["get", "type"],
                  ["literal", ["country", "city", "town", "village", "state"]],
                ],
                ["<=", ["get", "filterrank"], 4],
              ]}
              layout={{
                "text-field": ["get", "name"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-size": 12,
              }}
              paint={{
                "text-color": "#ffffff",
                "text-halo-width": 1,
                "text-opacity": 0.7,
              }}
            ></Layer>
          </Source>
        </>
      )}
    </>
  )
}
