import Modal from "./Modal"

export default function TermsOfService({ onClose }: { onClose: () => void }) {
  return (
    <Modal onClose={onClose}>
      <div className="px-6">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

        <p className="mb-4">
          Welcome to <strong>Sail Routing</strong> ("Company", "we", "our",
          "us"). These terms of service ("Terms") apply to your use of our
          website and services (collectively, the "Services"). By accessing or
          using our Services, you agree to be bound by these Terms. If you do
          not agree to these Terms, please do not use our Services.
        </p>

        <h2 className="text-2xl font-bold mb-4">1. Use of Services</h2>

        <p className="mb-4">
          You may use our Services only in compliance with these Terms and all
          applicable laws, rules, and regulations. You are not allowed to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Interfere with or disrupt the integrity or performance of our
            Services.
          </li>
          <li>
            Attempt to gain unauthorized access to our Services or their related
            systems or networks.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">2. Account Registration</h2>

        <p className="mb-4">
          To access certain features of our Services, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your password and for all activities that
          occur under your account. You agree to notify us immediately of any
          unauthorized use of your account.
        </p>

        <h2 className="text-2xl font-bold mb-4">
          3. Disclaimer and User Responsibility
        </h2>

        <p className="mb-4">
          The routing provided by our Services is based on weather data and
          algorithms that do not use nautical charts. Therefore, the proposed
          routing may not be realistic and could present risks. It is essential
          that you use the routing information with caution.
        </p>
        <p className="mb-4">
          You, as the user, are solely responsible for your navigation decisions
          and must adapt your navigation to the prevailing weather conditions.
          Our routing information is intended to serve as an aid to navigation,
          not as a definitive guide. Always consider additional information,
          such as nautical charts and local weather updates, before making
          navigation decisions.
        </p>
        <p className="mb-4">
          By using our Services, you acknowledge that [Your Company Name] cannot
          be held responsible for any incidents or accidents that may occur
          during your navigation. It is your responsibility to ensure the safety
          of your vessel and crew.
        </p>

        <h2 className="text-2xl font-bold mb-4">4. Termination</h2>

        <p className="mb-4">
          We may terminate or suspend your access to our Services, without prior
          notice or liability, for any reason, including if you breach these
          Terms. Upon termination, your right to use our Services will
          immediately cease.
        </p>

        <p className="mb-4">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will try to
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion. By continuing to access or use our Services after any
          revisions become effective, you agree to be bound by the revised
          Terms. If you do not agree to the new terms, please stop using the
          Services.
        </p>

        <h2 className="text-2xl font-bold mb-4">5. Contact Us</h2>

        <p className="mb-4">
          If you have any questions about these Terms, please contact us at:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>
            Email:{" "}
            <a href="mailto:contact@sailrouting.com" className="text-blue-600">
              contact@sailrouting.com
            </a>
          </li>
        </ul>
      </div>
    </Modal>
  )
}
