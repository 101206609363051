import { Popup } from "react-map-gl"
import { Point, RoutePoints } from "../../interfaces/interfaces"
import { useEffect, useState } from "react"
import { useGlobalStore } from "../../../store/globalStore"
var formatcoords = require("formatcoords")

export default function NoRoutingClickPopup({
  setRoutePoints,
  routePoints,
}: {
  setRoutePoints: (arg: any) => void
  routePoints: RoutePoints
}) {
  const clickedPoint = useGlobalStore((state) => state.clickedPoint)
  const showDefaultMapPopup = useGlobalStore(
    (state) => state.showDefaultMapPopup
  )
  const setShowDefaultMapPopup = useGlobalStore(
    (state) => state.setShowDefaultMapPopup
  )
  const definePoint = (pointType: string) => {
    setRoutePoints({
      ...routePoints,
      [pointType]: clickedPoint?.point,
    })
    setShowDefaultMapPopup(false)
  }
  const handleClose = () => {
    setShowDefaultMapPopup(false)
  }

  const listFormat = ["FFf", "Ff", "f"]

  const convertCoordinates = (clickedPoint: Point, format: string) => {
    return formatcoords(
      parseFloat(clickedPoint.latitude.toFixed(5)),
      parseFloat(clickedPoint.longitude.toFixed(5))
    ).format(format)
  }

  const [format, setFormat] = useState<string>("f")
  const [coords, setCoords] = useState<string>(
    clickedPoint ? convertCoordinates(clickedPoint.point, format) : ""
  )

  useEffect(() => {
    if (!clickedPoint) return
    const coordsFormatted = convertCoordinates(clickedPoint.point, format)
    setCoords(coordsFormatted)
  }, [format, clickedPoint])

  const handleCoordsClick = () => {
    let index = listFormat.indexOf(format)
    const new_format = listFormat[(index + 1) % listFormat.length]
    setFormat(new_format)
  }
  const [showToCloseToCoastMessage, setShowToCloseToCoastMessage] =
    useState(false)
  return (
    <>
      {showDefaultMapPopup && clickedPoint && (
        <Popup
          longitude={clickedPoint.point.longitude}
          latitude={clickedPoint.point.latitude}
          onClose={handleClose}
          closeOnClick={false}
        >
          <div className="flex gap-2 flex-col font-medium">
            <button onClick={handleCoordsClick}>
              <div>{coords.split("N")[0] + "N"}</div>
              <div>{coords.split("N")[1]}</div>
            </button>
            <div className="w-1/8 h-[1px] bg-gray-600 my-1"></div>
            {clickedPoint.clickOnUserLocation ? (
              <>
                <button
                  className="bg-red-300 hover:bg-red-400 p-2 rounded-md w-[120px]"
                  onClick={() => definePoint("startPoint")}
                >
                  Use my location as start point
                </button>
              </>
            ) : (
              <>
                <button
                  className="bg-slate-200 hover:bg-slate-300 p-2 rounded-md"
                  onClick={() => {
                    if (clickedPoint.clickIsCLoseToCoast) {
                      setShowToCloseToCoastMessage(true)
                      setShowDefaultMapPopup(false)
                      return
                    }
                    definePoint("startPoint")
                  }}
                >
                  Start point
                </button>
                <button
                  className="bg-slate-200 hover:bg-slate-300 p-2 rounded-md"
                  onClick={() => definePoint("finishPoint")}
                >
                  Finish point
                </button>
              </>
            )}
          </div>
        </Popup>
      )}
      {showToCloseToCoastMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-sm md:text-base font-bold">
              Your selected starting point is too close to the coastline. For
              optimal routing and safety, please choose a starting point further
              inland.
            </h2>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 font-bold"
                onClick={() => setShowToCloseToCoastMessage(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
