import { useContext, useState } from "react"
import Login from "./Login"
import Register from "./Register"
import { AuthContext } from "../../contexts/authContext"

export default function Sign() {
  const [mode, setMode] = useState<"login" | "register">("login")
  const { userLoggedIn } = useContext(AuthContext)
  return (
    <>
      {!userLoggedIn && mode === "login" && <Login setMode={setMode} />}
      {!userLoggedIn && mode === "register" && <Register setMode={setMode} />}
    </>
  )
}
