import * as turf from "@turf/turf"

export const isPointOnSea = (point: mapboxgl.Point, map: mapboxgl.Map) => {
  const features = map.queryRenderedFeatures(point)
  const landFeature = features.find(
    (feature) => feature.layer.id === "countries-layer-fill"
  )
  return landFeature === undefined
}

export const isPointCloseToCoast = (
  point: mapboxgl.Point,
  map: mapboxgl.Map
) => {
  const pointCoords = map.unproject(point)
  console.log(pointCoords)
  const bearings = [-180, -135, -90, -45, 0, 45, 90, 135]
  const distance = 1.852 / 3
  const points = bearings.map((bearing) => {
    return turf.destination(
      turf.point([pointCoords.lng, pointCoords.lat]),
      distance,
      bearing,
      {
        units: "kilometers",
      }
    )
  })
  const pointsMapbox = points.map((point) => {
    return map.project([
      point.geometry.coordinates[0],
      point.geometry.coordinates[1],
    ])
  })
  console.log(pointsMapbox)
  const pointsIsOnSea = pointsMapbox.map((point) => !isPointOnSea(point, map))
  console.log(pointsIsOnSea)
  return pointsIsOnSea.includes(true)
}
