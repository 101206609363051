//@ts-nocheck
import { MapRef } from "react-map-gl"
import { VectorField } from "./windParticules"
import { ForecastData } from "./types"

function getCanvasFromData(dataWidth: number, dataHeight: number, data: any) {
  //put static_data into canvas. Note -80 latitude is at the top of the image,
  //which helps with texture convention of top-left corner being 0,0

  const canvas_temp = document.createElement("canvas")
  canvas_temp.width = dataWidth
  canvas_temp.height = dataHeight
  const context = canvas_temp.getContext("2d") as CanvasRenderingContext2D

  const target = context.getImageData(0, 0, dataWidth, dataHeight)
  const targetData = target.data

  for (let i = 0; i < data.length / 4; i++) {
    targetData[i * 4] = 128 + data[i * 4]
    targetData[i * 4 + 1] = 128 + data[i * 4 + 1]
    targetData[i * 4 + 2] = 50
    // targetData[i * 4 + 2] = 255
    targetData[i * 4 + 3] = data[i * 4 + 3]
  }

  context.putImageData(target, 0, 0)
  return context.canvas
}

const convertData = (data: ForecastData) => {
  const u = data.u
  const v = data.v
  let dataFlatten = []
  for (let i = 0; i < u.length; i++) {
    for (let j = 0; j < u[0].length; j++) {
      // je dois inverser les latitudes, je ne sais pas pourquoi...
      let k = u.length - 1 - i
      dataFlatten.push(...[u[k][j], v[k][j], 0, 255])
    }
  }
  return dataFlatten
}

export const displayWindTrails = (
  mapRef: React.RefObject<MapRef>,
  data: ForecastData
) => {
  if (mapRef.current) {
    if (mapRef.current.getMap().getLayer("windTrails")) {
      mapRef.current.getMap().removeLayer("windTrails")
    }
    const dataFlatten = convertData(data)
    const width = data.width
    const height = data.height
    const map = mapRef.current.getMap()

    let vectorField: any
    const vectorData = {
      data: getCanvasFromData(width, height, dataFlatten),
      bounds: data.bounds,
      range: [-110, 110],
    }
    const customLayer = {
      id: "windTrails",
      type: "custom",
      onAdd: function (map: any, gl: any) {
        //create new VectorField instance
        vectorField = VectorField(map, gl)
        //set static_data for vector field
        vectorField.setData(vectorData)
      },
      render: function (gl: any, matrix: any) {
        //on Custom Layer render, draw the vector field
        vectorField.draw()
      },
    }
    map.addLayer(customLayer)

    return vectorField
  }
}
