import CloseLineIcon from "remixicon-react/CloseLineIcon"
import { useGlobalStore } from "../../store/globalStore"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "./DatePickerCustom.css"
import { AuthContext } from "../../contexts/authContext"

type props = {
  calculate: () => void
}

export default function RoutingConfirmation({ calculate }: props) {
  const { subscriptionType } = useContext(AuthContext)
  const routePoints = useGlobalStore((state) => state.routePoints)
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  const setSectionSettings = useGlobalStore((state) => state.setSectionSettings)
  const currentBoatName = useGlobalStore((state) => state.currentBoatName)
  const currentPolarData = useGlobalStore((state) => state.currentPolarData)
  const setShowRoutingConfirmation = useGlobalStore(
    (state) => state.setShowRoutingConfirmation
  )

  useEffect(() => {}, [])

  const setDepartureDate = useGlobalStore(
    (state) => state.setRoutingDepartureDate
  )
  const departureDate = useGlobalStore((state) => state.routingDepartureDate)
  const forecastRasterInfo = useGlobalStore((state) => state.forecastRasterInfo)
  const boatMotorSpeed = useGlobalStore((state) => state.boatMotorSpeed)
  const setBoatMotorSpeed = useGlobalStore((state) => state.setBoatMotorSpeed)

  const handleChangeDepartureDatetime = (date: Date) => {
    if (forecastRasterInfo === undefined) return
    setDepartureDate(moment(date))
  }
  const filterTime = (date: Date) => {
    const isPastTime = new Date().getTime() > date.getTime()
    return !isPastTime
  }
  return (
    <>
      {routePoints.startPoint === null || routePoints.finishPoint === null ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-sm sm:text-xl font-bold">
              Please click on the map to add a starting point and a destination
              before clicking ‘Launch Routing’.
            </h2>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 font-bold"
                onClick={() => setShowRoutingConfirmation(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-60 h-full z-10 top-0 right-0 absolute bg-slate-100 p-3 border-r-2 border-slate-400">
          <div className="relative">
            <div className="absolute right-0">
              <button onClick={() => setShowRoutingConfirmation(false)}>
                <CloseLineIcon />
              </button>
            </div>
            <div className="text-lg font-bold text-gray-800">ROUTING</div>
            <div className="text-gray-500 font-medium mb-3">
              Manage your preferences
            </div>
            {/* divider */}
            <div className="border-b border-gray-400 mb-3" />
            <div className="flex flex-col gap-2">
              <div>
                <div className="text-sm font-semibold text-gray-900  mb-2">
                  DEPARTURE DATE
                </div>
                <DatePicker
                  className="border border-gray-700 rounded-md w-full text-sm font-medium p-1"
                  showTimeSelect
                  filterTime={filterTime}
                  minDate={new Date()}
                  maxDate={
                    subscriptionType !== "basic"
                      ? moment().add(6, "days").toDate()
                      : moment().add(1, "days").toDate()
                  }
                  dateFormat="dd/MM/YYYY HH:mm"
                  selected={departureDate.toDate()}
                  onChange={(date) => {
                    if (date) {
                      handleChangeDepartureDatetime(date)
                    }
                  }}
                />
                {subscriptionType === "basic" && (
                  <>
                    <div className="text-red-600 font-medium">
                      With the basic plan, departure dates are limited to 24
                      hours
                    </div>
                    <button
                      onClick={() => {
                        setSectionSettings("plans")
                        setShowSettings(true)
                      }}
                      className="text-left text-sm font-semibold underline text-red-800"
                    >
                      Upgrade my plan
                    </button>
                  </>
                )}

                {/* <input
                  className="border border-gray-700 rounded-md p-1 w-full text-sm font-medium"
                  type="datetime-local"
                  min={moment().format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) =>
                    handleChangeDepartureDatetime(e.target.value)
                  }
                  value={departureDate.format("YYYY-MM-DDTHH:mm")}
                /> */}
              </div>
              <div className="border-b border-gray-400 mb-3" />

              <div className="text-sm font-semibold text-gray-900">MY BOAT</div>
              <div className="flex gap-2 items-center mb-2">
                {currentPolarData && (
                  <div className="flex flex-col gap-1 font-semibold">
                    <div className="text-sm text-red-800">{currentBoatName}</div>
                    <ul className="list-disc list-inside">
                      {currentPolarData.beat_angle && (
                        <li>beat angle: {currentPolarData.beat_angle}°</li>
                      )}
                      <li>
                        spinnaker: {currentPolarData.has_spi ? "yes" : "no"}
                      </li>
                      <li>
                        performance adjustment:{" "}
                        {currentPolarData?.perf_factor * 100 + " %"}
                      </li>
                    </ul>
                    <button
                      className="bg-white py-1 px-2 rounded-md border font-medium border-gray-700 text-sm hover:bg-gray-100 w-1/2"
                      onClick={() => {
                        setShowSettings(true)
                        setSectionSettings("my boat")
                      }}
                    >
                      Modify
                    </button>
                  </div>
                )}
              </div>
              <div className="border-b border-gray-400 mb-3" />
              <div className="text-sm font-semibold text-gray-900">
                SETTINGS
              </div>
              <div>Minimum speed (kts) - start motoring</div>
              <input
                type="number"
                step={0.1}
                min={0}
                max={15}
                value={boatMotorSpeed}
                onChange={(e) => setBoatMotorSpeed(parseFloat(e.target.value))}
                className="border border-gray-700 rounded-md p-1 w-1/4 text-sm w-full font-medium"
              ></input>
            </div>

            <div className="flex justify-center mt-4">
              <button
                onClick={calculate}
                className="bg-red-700 text-white py-2 px-10 rounded-md drop-shadow-lg hover:bg-red-800"
              >
                <div className="font-bold text-sm">GO</div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
