export const gradientColors = [
  { color: "rgba(98, 113, 183, 1)", value: 0 },
  { color: "rgba(57, 97, 159, 1)", value: 2 },
  { color: "rgba(74, 148, 169, 1)", value: 6 },
  { color: "rgba(77, 141, 123, 1)", value: 10 },
  { color: "rgba(83, 165, 83, 1)", value: 14 },
  { color: "rgba(53, 159, 53, 1)", value: 17 },
  { color: "rgba(167, 157, 81, 1)", value: 21 },
  { color: "rgba(159, 127, 58, 1)", value: 25 },
  { color: "rgba(161, 108, 92, 1)", value: 29 },
  { color: "rgba(129, 58, 78, 1)", value: 33 },
  { color: "rgba(175, 80, 136, 1)", value: 37 },
  { color: "rgba(117, 74, 147, 1)", value: 41 },
  { color: "rgba(109, 97, 163, 1)", value: 47 },
  { color: "rgba(68, 105, 141, 1)", value: 52 },
  { color: "rgba(92, 144, 152, 1)", value: 56 },
  { color: "rgba(125, 68, 165, 1)", value: 70 },
  { color: "rgba(231, 215, 215, 1)", value: 89 },
  { color: "rgba(219, 212, 135, 1)", value: 99 },
  { color: "rgba(205, 202, 112, 1)", value: 150 },
  { color: "rgba(128, 128, 128, 1)", value: 202 },
]

export const getRasterColor = (
  gradientColors: { color: string; value: number }[]
) => {
  let rasterColor: (string | number | string[])[] = [
    "interpolate",
    ["linear"],
    ["raster-value"],
  ]
  gradientColors.forEach((item) => {
    rasterColor.push(item.value)
    rasterColor.push(item.color)
  })
  return rasterColor
}

// Helper function to parse RGBA color strings
const parseRGBA = (color: string): number[] => {
  const matches = color.match(/rgba?\((\d+), (\d+), (\d+),? ([\d\.]+)?\)/)
  if (matches) {
    return [
      parseInt(matches[1]),
      parseInt(matches[2]),
      parseInt(matches[3]),
      parseFloat(matches[4]),
    ]
  }
  return [0, 0, 0, 1]
}

// Helper function for linear interpolation
const lerp = (a: number, b: number, t: number): number => {
  return a + (b - a) * t
}

// Function to interpolate color based on value
export const getColorForValue = (value: number): number[] => {
  // Find two surrounding colors
  let lower = gradientColors[0]
  let upper = gradientColors[gradientColors.length - 1]

  for (let i = 0; i < gradientColors.length - 1; i++) {
    if (
      value >= gradientColors[i].value &&
      value <= gradientColors[i + 1].value
    ) {
      lower = gradientColors[i]
      upper = gradientColors[i + 1]
      break
    }
  }

  // Calculate interpolation factor
  const t = (value - lower.value) / (upper.value - lower.value)

  // Parse RGBA components
  const lowerRGBA = parseRGBA(lower.color)
  const upperRGBA = parseRGBA(upper.color)

  // Interpolate each component
  const interpolatedRGBA = lowerRGBA.map((component, index) =>
    lerp(component, upperRGBA[index], t)
  )
  return interpolatedRGBA.map((component) => Math.round(component))
}
