import { useRef, useState } from "react"
import { useGlobalStore } from "../../store/globalStore"
import BoatSettings from "./BoatSettings"
import { ReactComponent as ExpandRightIcon } from "../../components/icons/expandRight.svg"
import { ReactComponent as ExpandLeftIcon } from "../../components/icons/expandLeft.svg"
import { useOutsideAlerter } from "../../utils/clickOutside"
import Pricing from "../components/Pricing"
import { set } from "lodash"

export default function Settings() {
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  const sectionSettings = useGlobalStore((state) => state.sectionSettings)
  const setSectionSettings = useGlobalStore((state) => state.setSectionSettings)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const menuMobileFullRef = useRef(null)
  useOutsideAlerter(menuMobileFullRef, setShowMobileMenu)
  return (
    <div className="w-full bg-gray-100 h-full z-40 flex absolute top-0">
      <div className="flex-col px-6 pt-3 gap-2 items-start text-gray-800 w-[244px] hidden lg:block border-r-2 border-slate-400">
        <div className="font-bold">Settings</div>
        <button
          className={`whitespace-nowrap px-2 py-1 w-full text-left rounded-md hover:bg-gray-200  ${
            sectionSettings === "my boat" && "bg-slate-200"
          }`}
          onClick={() => {
            setSectionSettings("my boat")
            setShowSettings(true)
          }}
        >
          My boat
        </button>
        <button
          className={`whitespace-nowrap px-2 py-1 w-full text-left rounded-md hover:bg-gray-200 ${
            sectionSettings === "plans" && "bg-slate-200"
          }`}
          onClick={() => {
            setSectionSettings("plans")
            setShowSettings(true)
          }}
        >
          Plans
        </button>
      </div>
      <div className="block lg:hidden p-2 text-gray-600 border-r-2 border-slate-400">
        {!showMobileMenu ? (
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="flex flex-row"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="#5c5c5e">
              <path d="M15 5.25A3.25 3.25 0 0 0 11.75 2h-7.5A3.25 3.25 0 0 0 1 5.25v5.5A3.25 3.25 0 0 0 4.25 14h7.5A3.25 3.25 0 0 0 15 10.75v-5.5Zm-3.5 7.25H7v-9h4.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2Zm-6 0H4.25a1.75 1.75 0 0 1-1.75-1.75v-5.5c0-.966.784-1.75 1.75-1.75H5.5v9Z"></path>
            </svg>
            <div className="w-4">
              <ExpandRightIcon />
            </div>
          </button>
        ) : (
          <div
            ref={menuMobileFullRef}
            className="top-0 left-0 flex-col px-6 py-3 gap-2 items-start text-gray-800 w-[244px] absolute bg-gray-100 h-full z-50 border-r-2 border-slate-400"
          >
            <div>
              <div className="flex justify-between mb-3">
                <div className="flex gap-2">
                  <button
                    className="text-black w-4"
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                  >
                    <ExpandLeftIcon />
                  </button>

                  <div className="font-bold">Settings</div>
                </div>
              </div>
              <button
                className={`whitespace-nowrap px-2 py-1 w-full text-left rounded-md hover:bg-gray-200  ${
                  sectionSettings === "my boat" && "bg-slate-200"
                }`}
                onClick={() => {
                  setSectionSettings("my boat")
                  setShowSettings(true)
                  setShowMobileMenu(false)
                }}
              >
                My boat
              </button>
              <button
                className={`whitespace-nowrap px-2 py-1 w-full text-left rounded-md hover:bg-gray-200 ${
                  sectionSettings === "plans" && "bg-slate-200"
                }`}
                onClick={() => {
                  setSectionSettings("plans")
                  setShowSettings(true)
                  setShowMobileMenu(false)
                }}
              >
                Plans
              </button>
            </div>
            <div className="w-[1px] bg-gray-400"></div>
          </div>
        )}
      </div>
      <div className="w-full bg-white flex flex-row justify-center overflow-y-auto">
        {sectionSettings === "my boat" && <BoatSettings />}
        {sectionSettings === "plans" && <Pricing />}
      </div>
    </div>
  )
}
