import {
  ForecastData,
  ForecastPointData,
  ForecastRasterInfo,
  PolarData,
  Routing,
} from "./../utils/types"
import { Moment } from "moment"
import { create } from "zustand"
import { ClickData, RoutePoints } from "../components/interfaces/interfaces"
import moment from "moment"
import { auth } from "../firebase/firebase"

export interface MapBounds {
  northEast: { latitude: number; longitude: number }
  southWest: { latitude: number; longitude: number }
}

interface GlobalStore {
  showGradient: boolean
  setShowGradient: (showGradient: boolean) => void
  forecastDate: Moment | undefined //UTC
  setForecastDate: (forecastDate: Moment) => void
  forecastRasterInfo: ForecastRasterInfo | undefined
  setForecastRasterInfo: (forecastRasterInfo: ForecastRasterInfo) => void
  forecastPointData: undefined | ForecastPointData
  setForecastPointData: (forecastPointData: ForecastPointData) => void
  showDefaultMapPopup: boolean
  setShowDefaultMapPopup: (showDefaultMapPopup: boolean) => void
  routing: Routing | null
  setRouting: (routing: Routing | null) => void
  clickedPoint: ClickData | null
  setClickedPoint: (clickedPoint: ClickData | null) => void
  routePoints: RoutePoints
  setRoutePoints: (routePoints: RoutePoints) => void
  showRoutingColorLayer: boolean
  setShowRoutingColorLayer: (showRoutingColorLayer: boolean) => void
  routingDepartureDate: Moment
  setRoutingDepartureDate: (routingDepartureDate: Moment) => void
  showSettings: boolean
  setShowSettings: (showSettings: boolean) => void
  currentBoatName: string | undefined
  fetchAndSetBoatName: () => void
  currentPolarData: PolarData | undefined
  setCurrentPolarData: () => void
  boatMotorSpeed: number
  setBoatMotorSpeed: (boatMotorSpeed: number) => void
  showRoutingConfirmation: boolean
  setShowRoutingConfirmation: (showRoutingConfirmation: boolean) => void
  mapBounds: MapBounds | undefined
  setMapBounds: (bounds: mapboxgl.LngLatBounds) => void
  forecastData: ForecastData | undefined
  setForecastData: (forecastData: ForecastData | undefined) => void
  sectionSettings: string
  setSectionSettings: (sectionSettings: string) => void
}

export const useGlobalStore = create<GlobalStore>((set) => ({
  showGradient: true,
  setShowGradient: (showGradient: boolean) =>
    set(() => ({ showGradient: showGradient })),
  forecastDate: undefined,
  setForecastDate: (forecastDate: Moment) =>
    set(() => ({ forecastDate: forecastDate })),
  forecastRasterInfo: undefined,
  setForecastRasterInfo: (forecastRasterInfo: ForecastRasterInfo) =>
    set(() => ({ forecastRasterInfo: forecastRasterInfo })),
  forecastPointData: undefined,
  setForecastPointData: (forecastPointData: ForecastPointData) =>
    set(() => ({ forecastPointData: forecastPointData })),
  showDefaultMapPopup: false,
  setShowDefaultMapPopup: (showDefaultMapPopup: boolean) =>
    set(() => ({ showDefaultMapPopup: showDefaultMapPopup })),
  routing: null,
  setRouting: (routing: Routing | null) => set(() => ({ routing: routing })),
  clickedPoint: null,
  setClickedPoint: (clickedPoint: ClickData | null) =>
    set(() => ({ clickedPoint: clickedPoint })),
  routePoints: {
    startPoint: null,
    finishPoint: null,
  },
  setRoutePoints: (routePoints: RoutePoints) =>
    set(() => ({ routePoints: routePoints })),
  showRoutingColorLayer: false,
  setShowRoutingColorLayer: (showRoutingColorLayer: boolean) =>
    set(() => ({ showRoutingColorLayer: showRoutingColorLayer })),
  routingDepartureDate: moment()
    .add(15 - (moment().minute() % 15), "minutes")
    .startOf("minute"),
  setRoutingDepartureDate: (routingDepartureDate: Moment) =>
    set(() => ({ routingDepartureDate: routingDepartureDate })),
  showSettings: false,
  setShowSettings: (showSettings: boolean) =>
    set(() => ({ showSettings: showSettings })),
  currentBoatName: undefined,
  fetchAndSetBoatName: async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      fetch(`${backendUrl}/polar/name/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((name: string) => {
          set({ currentBoatName: name })
        })
    }
  },
  currentPolarData: undefined,
  setCurrentPolarData: async () => {
    const token = await auth.currentUser?.getIdToken()
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    if (token) {
      fetch(`${backendUrl}/polar/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data: PolarData) => {
          set({ currentPolarData: data })
        })
    }
  },
  boatMotorSpeed: 4,
  setBoatMotorSpeed: (boatMotorSpeed: number) =>
    set(() => ({ boatMotorSpeed: boatMotorSpeed })),
  showRoutingConfirmation: false,
  setShowRoutingConfirmation: (showRoutingConfirmation: boolean) =>
    set(() => ({ showRoutingConfirmation: showRoutingConfirmation })),
  mapBounds: undefined,
  setMapBounds: (bounds: mapboxgl.LngLatBounds) => {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    set(() => ({
      mapBounds: {
        northEast: { latitude: ne.lat, longitude: ne.lng },
        southWest: { latitude: sw.lat, longitude: sw.lng },
      },
    }))
  },
  forecastData: undefined,
  setForecastData: (forecastData: ForecastData | undefined) =>
    set(() => ({ forecastData: forecastData })),
  sectionSettings: "my boat",
  setSectionSettings: (sectionSettings: string) =>
    set(() => ({ sectionSettings: sectionSettings })),
}))
