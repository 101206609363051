export default function Toggle({
  isChecked,
  setChecked,
}: {
  isChecked: boolean
  setChecked: (arg: boolean) => void
}) {
  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        onChange={() => {
          setChecked(!isChecked)
        }}
        checked={isChecked}
      />
      <div className="relative w-7 h-4 bg-gray-200 peer peer-focus:outline-none peer-focus:ring-blue-300 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
  )
}
