import { useGlobalStore } from "../../../store/globalStore"
import { CalculationInfo } from "../../../utils/types"
import WindScale from "../../WindScale"
import { RoutePoints } from "../../interfaces/interfaces"
import RoutingConfirmation from "../../routingConfirmation/RoutingConfirmation"
import { ErrorMessage } from "../ModuleRouting"
import ModalRoutingLoading from "./ModalRoutingLoading"

export default function RoutingOverLay({
  routingIsLoading,
  calculate,
  errorMessage,
  setRoutingIsLoading,
  setErrorMessage,
  calculationInfo,
}: {
  routingIsLoading: boolean
  calculate: () => void
  routePoints: RoutePoints
  errorMessage: ErrorMessage | null
  setRoutingIsLoading: (arg: boolean) => void
  setErrorMessage: (arg: ErrorMessage | null) => void
  calculationInfo: CalculationInfo | null
}) {
  const showRoutingColorLayer = useGlobalStore(
    (state) => state.showRoutingColorLayer
  )
  const showRoutingConfirmation = useGlobalStore(
    (state) => state.showRoutingConfirmation
  )

  return (
    <>
      {showRoutingConfirmation && <RoutingConfirmation calculate={calculate} />}
      {showRoutingColorLayer && <WindScale />}
      {routingIsLoading && (
        <ModalRoutingLoading
          errorMessage={errorMessage}
          setRoutingIsLoading={setRoutingIsLoading}
          setErrorMessage={setErrorMessage}
          calculationInfo={calculationInfo}
        />
      )}
    </>
  )
}
