type Props = {
  kind: "start" | "finish";
}
export default function IconPin({ kind }: Props) {
  const text = kind === "start" ? "S" : "F";
  const color = kind === "start" ? "rgba(0, 128, 0, 0.5)" : "rgba(255, 0, 0, 0.5)";
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M24 46C24 46 10 30 10 20C10 10.6112 16.6112 4 24 4C31.3888 4 38 10.6112 38 20C38 30 24 46 24 46Z"
    fill={color}
    stroke="black"
    strokeWidth="2"
  />
  <text
    x="24"
    y="28"
    textAnchor="middle"
    fontFamily="Arial"
    fontSize="16"
    fill="white"
    fontWeight="bold"
  >
    {text}
  </text>
</svg>
  )
}
