import proj4 from "proj4"
import { MapBounds } from "../store/globalStore"

function calculateWindSpeed(u: number[][], v: number[][]): number[][] {
  const numRows = u.length
  const numCols = u[0].length
  const windSpeed: number[][] = []

  for (let i = 0; i < numRows; i++) {
    windSpeed[i] = []
    for (let j = 0; j < numCols; j++) {
      windSpeed[i][j] = Math.sqrt(u[i][j] ** 2 + v[i][j] ** 2)
    }
  }

  return windSpeed
}
export function getCanvasFromData(
  u: number[][],
  v: number[][],
  longitudes: number[],
  latitudes: number[]
) {
  const wgs84 = "EPSG:4326" // WGS84
  const mercator = "EPSG:3857" // Spherical Mercator
  const canvas_temp = document.createElement("canvas")
  const scale = 1
  const width = longitudes.length / scale
  const height = latitudes.length / scale
  canvas_temp.width = width
  canvas_temp.height = height
  const context = canvas_temp.getContext("2d") as CanvasRenderingContext2D

  const target = context.getImageData(0, 0, width, height)
  const targetData = target.data

  let speeds = calculateWindSpeed(u, v)
  const up_left_point_mercator = proj4(wgs84, mercator, [
    longitudes[0],
    latitudes[0],
  ])
  const up_right_point_mercator = proj4(wgs84, mercator, [
    longitudes[longitudes.length - 1],
    latitudes[0],
  ])
  if (!up_right_point_mercator) {
    console.log(
      longitudes[0],
      latitudes[0],
      longitudes[longitudes.length - 1],
      latitudes[latitudes.length - 1]
    )
  }
  const down_left_point_mercator = proj4(wgs84, mercator, [
    longitudes[0],
    latitudes[latitudes.length - 1],
  ])
  const delta_x_mercator =
    (up_right_point_mercator[0] - up_left_point_mercator[0]) / target.width
  const delta_y_mercator =
    (up_left_point_mercator[1] - down_left_point_mercator[1]) / target.height
  for (let x = 0; x < target.width; x++) {
    for (let y = 0; y < target.height; y++) {
      // x balaye de gauche à droite
      // y balaye de haut en bas
      // départ en haut à gauche

      const index = (y * target.width + x) * 4
      const x_mercator = up_left_point_mercator[0] + x * delta_x_mercator
      const y_mercator = up_left_point_mercator[1] - y * delta_y_mercator
      // const longitude = longitudes[x]
      // const latitude = latitudes[latitudes.length - y]
      const [longitude, latitude] = proj4(mercator, wgs84, [
        x_mercator,
        y_mercator,
      ])
      const speed = interpolateWindSpeed(
        latitudes,
        longitudes,
        speeds,
        latitude,
        longitude
      )
      targetData[index] = speed ? speed : 0
      // targetData[cpt * 4] = speeds[j][i]
      targetData[index + 1] = 0
      targetData[index + 2] = 0
      targetData[index + 3] = speed ? 255 : 0
    }
  }

  context.putImageData(target, 0, 0)
  return context.canvas
}
function interpolateWindSpeed(
  latitudes: number[],
  longitudes: number[],
  windSpeeds: number[][],
  targetLatitude: number,
  targetLongitude: number
): number | undefined {
  // Trouver les indices des latitudes et longitudes encadrant la position cible
  const latIndex = findBoundingIndex(latitudes, targetLatitude, false)
  const lonIndex = findBoundingIndex(longitudes, targetLongitude)

  if (latIndex === -1 || lonIndex === -1) {
    return undefined
  }

  // Extraire les points encadrants pour l'interpolation
  const lat1 = latitudes[latIndex]
  const lat2 = latitudes[latIndex + 1]
  const lon1 = longitudes[lonIndex]
  const lon2 = longitudes[lonIndex + 1]

  const Q11 = windSpeeds[latIndex][lonIndex]
  const Q12 = windSpeeds[latIndex][lonIndex + 1]
  const Q21 = windSpeeds[latIndex + 1][lonIndex]
  const Q22 = windSpeeds[latIndex + 1][lonIndex + 1]

  // Effectuer l'interpolation bilinéaire
  const interpolatedValue = bilinearInterpolation(
    lat1,
    lat2,
    lon1,
    lon2,
    Q11,
    Q12,
    Q21,
    Q22,
    targetLatitude,
    targetLongitude
  )

  return interpolatedValue
}

function findBoundingIndex(
  array: number[],
  value: number,
  isAscending: boolean = true
): number {
  if (isAscending) {
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i] <= value && value <= array[i + 1]) {
        return i
      }
    }
  } else {
    for (let i = 0; i < array.length - 1; i++) {
      if (array[i] >= value && value >= array[i + 1]) {
        return i
      }
    }
  }
  return -1 // Si hors des limites
}

function bilinearInterpolation(
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  Q11: number,
  Q12: number,
  Q21: number,
  Q22: number,
  x: number,
  y: number
): number {
  const R1 = ((x2 - x) / (x2 - x1)) * Q11 + ((x - x1) / (x2 - x1)) * Q21
  const R2 = ((x2 - x) / (x2 - x1)) * Q12 + ((x - x1) / (x2 - x1)) * Q22
  const P = ((y2 - y) / (y2 - y1)) * R1 + ((y - y1) / (y2 - y1)) * R2
  return P
}
function transpose(matrix: any[][]): any[][] {
  return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]))
}

export const get_data_zone = (mapBounds: MapBounds) => {
  const delta_x_min = 10
  const delta_y_min = 5
  const delta_x = mapBounds.northEast.longitude - mapBounds.southWest.longitude
  const delta_y = mapBounds.northEast.latitude - mapBounds.southWest.latitude
  const k = 0.25
  let minx = Math.floor(mapBounds.southWest.longitude - delta_x * k)
  let miny = Math.floor(mapBounds.southWest.latitude - delta_y * k)
  let maxx = Math.ceil(mapBounds.northEast.longitude + delta_x * k)
  let maxy = Math.ceil(mapBounds.northEast.latitude + delta_y * k)
  if (delta_x < delta_x_min) {
    minx = minx - (delta_x_min - delta_x) / 2
    maxx = maxx + (delta_x_min - delta_x) / 2
  }
  if (delta_y < delta_y_min) {
    miny = miny - (delta_y_min - delta_y) / 2
    maxy = maxy + (delta_y_min - delta_y) / 2
  }
  miny = Math.max(miny, -80)
  maxy = Math.min(maxy, 80)
  minx = Math.max(minx, -180)
  maxx = Math.min(maxx, 180)
  return { minx, miny, maxx, maxy }
}
